








































import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { MintViewModel } from '../../viewmodels/mint-viewmodel'

@Observer
@Component({
  components: {
    'mint-countdown': () => import('@/modules/mint/components/process/mint-countdown.vue'),
    'whitelist-result-no-win': () => import('@/modules/mint/components/process/whitelist-result-no-win.vue'),
  },
})
export default class extends Vue {
  @Inject({}) vm!: MintViewModel

  // isWhitelist = true
  // isOptIn = false
  // optIn() {
  //   this.isOptIn = true
  // }
}
